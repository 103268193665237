<template>
    <div class="carousel-content">
        <div class="carousel-title">轮播管理</div>
        <div class="btn-carousel">
            <el-button size="medium" class="btn-green add-btn" @click="addCarousel">新增轮播</el-button>
        </div>
        <el-table :data="carouselList" style="width: 100%; flex: 1" height="1%" size="medium" header-cell-class-name="table-header-cell"
                  :header-cell-style="{fontWeight: 'normal', color: '#444', background: '#F5F7FA',height:'50px',fontSize: '16px'}"
                  :cell-style="{fontSize: '14px',color: '#666',height: '76px'}">
            <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
            <el-table-column label="轮播图">
                <template slot-scope="scope">
                    <div class="cover-info">
                        <img :src="scope.row.pic_url_path.filepath" alt="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="sort" label="轮播图排序" align="center"></el-table-column>
            <el-table-column prop="addtime" label="发布时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="220">
                <template slot-scope="scope">
                    <el-button size="small" type="primary" plain @click="viewCarousel">查看</el-button>
                    <el-button size="small" type="primary" @click="editCarousel(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="delCarousel(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="carouselPages.currentPageNum"
                :page-size="carouselPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="carouselPages.total"
                @current-change="carouselCurrentChange">
        </el-pagination>
        <el-dialog :title="addCarouselDialogTitle" :visible.sync="addCarouselDialog" ref="addCarouselDialog" width="650px" custom-class="carousel-dialog"
                   :close-on-click-modal="false" @close="closeCarouselDialog">
            <el-scrollbar class="form-content">
                <el-form ref="addCarouselForm" :model="addCarouselForm" :rules="addCarouselRules" class="carousel-box">
                    <el-form-item label="轮播图上传" :label-width="formLabelWidth" prop="carousel_img" class="carousel-item">
                        <div>
                            <el-button type="primary" v-model="addCarouselForm.carousel_img" class="upload-btn" size="medium">
                                <div class="icon">
                                    <i class="el-icon-circle-plus"></i>
                                    <span>点击上传图片</span>
                                </div>
                                <input type="file" accept="image/jpg, image/jpeg, image/png" title="" @change="changeCarouselFile($event)">
                            </el-button>
                            <div class="up-img-show" v-if="addCarouselForm.carousel_img">
                                <div class="afresh-upload">
                                    <div class="bg"></div>
                                    <span class="afresh-span">重新上传</span>
                                    <input type="file" accept="image/jpg, image/jpeg, image/png" class="afresh-input" @change="changeCarouselFile($event)">
                                </div>
                                <img :src="addCarouselForm.carousel_img" alt />
                            </div>
                        </div>
                        <span class="prompt-msg" style="margin-top: 10px;">轮播图尺寸：<span class="em">1925*466</span></span>
                    </el-form-item>
                    <el-form-item label="轮播图排序" prop="sort" :label-width="formLabelWidth" class="carousel-item">
                        <el-input-number v-model="addCarouselForm.sort"
                                         placeholder="排序区间：1-255"
                                         :min="1"
                                         :max="255"
                                         :controls="false"
                                         :precision="0"
                                         class="input-number">
                        </el-input-number>
                    </el-form-item>
                </el-form>
            </el-scrollbar>
            <div slot="footer" style="text-align: center">
                <el-button class="btn" @click="addCarouselDialog = false">取消</el-button>
                <el-button class="btn-green btn" size="medium" @click="saveCarousel('addCarouselForm')">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {banner, bannerAdd, bannerUploadBanner, bannerEdit, bannerDel} from '../../../utils/apis'
    export default {
        name: "CarouselManage",
        data() {
            return {
                //轮播列表
                carouselList:[],
                //分页
                carouselPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                },
                //是否显示弹窗
                addCarouselDialog: false,
                //弹窗标题
                addCarouselDialogTitle: '新增轮播',
                formLabelWidth: '110px',
                //新增轮播表单数据
                addCarouselForm: {
                    id: '',
                    carousel_img: '',
                    imageFile: '',
                    sort: ''
                },
                addCarouselRules: {
                    carousel_img: [
                        { required: true, message: '请上传轮播图', trigger: 'change' }
                    ],
                    sort: [
                        {required: true, message: '请输入轮播图排序', trigger: 'blur'}
                    ],
                }
            }
        },
        created() {
          this.getCarouselList();
        },
        methods:{
            //获取轮播图列表
            getCarouselList() {
                let params = {
                    type: '2',
                    paging: 1,
                    page: this.carouselPages.currentPageNum,
                    pageSize: this.carouselPages.eachPageNum,
                }
                banner(params).then((res) => {
                    this.carouselList = res.data.list;
                    this.carouselPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //新增
            addCarousel(){
                this.addCarouselDialog = true;
            },
            //查看
            viewCarousel() {
                let routeUrl = this.$router.resolve({
                    path: "/"
                });
                window.open(routeUrl.href, '_blank');
            },
            //编辑
            editCarousel(row) {
                this.addCarouselDialog = true;
                this.addCarouselForm.id = row.banner_id;
                this.addCarouselForm.sort = row.sort;
                this.addCarouselForm.carousel_img = row.pic_url_path.filepath;
                this.addCarouselForm.imageFile = row.pic_url_path.fullpath;
            },
            //删除
            delCarousel(row) {
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let formData = new FormData();
                    formData.append('banner_id', row.banner_id);
                    bannerDel(formData).then((res) => {
                        this.$message.success(res.msg);
                        this.getCarouselList();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //分页
            carouselCurrentChange(val) {
                this.carouselPages.currentPageNum = val;
                this.getCarouselList();
            },
            //上传轮播图
            changeCarouselFile(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                this.addCarouselForm.imageFile = fileList;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    let img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        if (img.width === 1925 && img.height === 466) {
                            let formData = new FormData();
                            formData.append('banner', that.addCarouselForm.imageFile);
                            bannerUploadBanner(formData).then((res) => {
                                that.$message.success(res.msg);
                                that.addCarouselForm.carousel_img = res.data.filepath;
                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            that.$message({
                                type: "error",
                                message: "图片尺寸必须为 1925*466",
                            });
                        }
                    }
                }
                event.target.value = '';
            },
            //保存
            saveCarousel(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addCarouselForm.id) {
                            let formData = new FormData();
                            formData.append('banner_id', this.addCarouselForm.id);
                            formData.append('sort', this.addCarouselForm.sort);
                            formData.append('pic_url', this.addCarouselForm.carousel_img);
                            bannerEdit(formData).then((res) => {
                                this.$message.success(res.msg);
                                this.addCarouselDialog = false;
                                this.getCarouselList();
                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            let formData = new FormData();
                            formData.append('sort', this.addCarouselForm.sort);
                            formData.append('pic_url', this.addCarouselForm.carousel_img);
                            bannerAdd(formData).then((res) => {
                                this.$message.success(res.msg);
                                this.addCarouselDialog = false;
                                this.getCarouselList();
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                    }
                });
            },
            //重置新增轮播弹窗数据
            closeCarouselDialog() {
                this.addCarouselForm.id = '';
                this.addCarouselForm.sort = '';
                this.addCarouselForm.carousel_img = '';
            }
        }
    }
</script>

<style scoped lang="scss">
    .carousel-content{
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 100%;
        padding:0 20px;
        box-sizing: border-box;
        .carousel-title{
            border-bottom: 1px solid #dcdcdc;
            padding: 30px 0 20px;
            box-sizing: border-box;
            font-size: 16px;
            color: #222;
        }
        .btn-carousel{
            display: flex;
            justify-content: flex-end;
            padding: 10px 0;
            box-sizing: border-box;
            .add-btn {
                padding: 10px 16px;
            }
        }
        .cover-info{
            display: inline-block;
            width: 273px;
            height:66px;
        }
        .logo-info{
            display: inline-block;
            width: 42px;
            height: 42px;
        }
        img{
            max-width: 100%;
            max-height: 100%;
        }
        ::v-deep .el-table .cell{
            padding-right: 0;
        }
        ::v-deep .el-table .cell,::v-deep .el-table--border td:first-child .cell, ::v-deep  .el-table--border th:first-child .cell {
            padding-left:0;
        }
    }
    ::v-deep .el-dialog__wrapper {
        .carousel-dialog {
            .el-dialog__header {
                border-bottom: 1px solid #DCDCDC;
                padding: 20px 0 10px 0;
                margin: 0 20px;
            }
            .el-dialog__body {
                padding: 63px 20px;
            }
            .carousel-box {
                .carousel-item {
                    .el-form-item__label {
                        color: #666;
                        font-size: 16px;
                    }
                    .input-number {
                        width: 478px;
                    }
                    .el-input-number.is-without-controls .el-input__inner {
                        text-align: left;
                    }
                }
            }
            .upload-btn {
                position: relative;
                width: 382px;
                height: 92px;
                background: #F2F2F2;
                border-color: transparent;
                .icon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    .el-icon-circle-plus {
                        color: #FF0013;
                        font-size: 26px;
                    }
                    span {
                        font-size: 14px;
                        color: #666666;
                        margin-top: 10px;
                    }
                }
                input {
                    width: 382px;
                    height: 92px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                }
                .el-icon-plus {
                    color: #FF7128;
                    font-size: 30px;
                }
            }
            .prompt-msg {
                color: #666;
                font-size: 16px;
                .em {
                    color: #FF0101;
                }
            }
            .up-img-show {
                position: absolute;
                top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 382px;
                height: 92px;
                background-color: #fff;
                border: 1px solid #bfbfbf6b;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
                video {
                    width: 100%;
                    height: 100%;
                }
                .afresh-upload {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 30px;
                    .bg {
                        width: 100%;
                        height: 100%;
                        background-color: #333;
                        opacity: 0.5;
                    }
                    .afresh-span {
                        position: absolute;
                        top: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        line-height: 30px;
                        text-align: center;
                        cursor: pointer;
                    }
                    .afresh-input {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        opacity: 0;
                    }
                }
            }
            .btn {
                padding: 10px 30px;
            }
        }
    }
</style>